<template>
  <div>
    <div class="flex">
      <div id="logo" class="flex-1 bg-gray-50 px-5 py-5 lg:flex">
        <div class="mx-auto flex h-full items-center">
          <LogoMango />
        </div>
      </div>
      <div class="min-h-screen flex-1">
        <div class="flex h-full items-center py-8 px-5">
          <LoginForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/Componentes/LoginForm.vue";
import LogoMango from "@/Componentes/LogoMango.vue";
export default {
  components: { LoginForm, LogoMango },
  name: 'LoginPage',
  layout: 'login',
}
</script>

<style lang="scss" scoped>
@media (max-width: 2000px) {
  div#logo {
    display: flex;
    background-color: var(--color-5);
  }
}

@media (max-width: 800px) {
  div#logo {
    display: none;
  }
}

@media (max-width: 500px) {
  div#logo {
    display: none;
  }
}
</style>