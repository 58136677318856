<script>
import { GetCookie, SetCookie } from "@/Constants/Functions";
export default {
  computed: {
  },
  methods: {
    GetToken() {
      return GetCookie("token");
    },
    IsTokenExpired() {
      if(!this.IsLogin())
        return true;
      const now = new Date();
      const currentTime = Math.floor(now.getTime() / 1000);
      const tokenExpirationTime = GetCookie("tokenExpiration");
      return (currentTime>tokenExpirationTime);
    },
    IsLogin() {
      return GetCookie("token") != null;
    },
    User(){
      const user = JSON.parse(GetCookie("user"));
      return user;
    },
    Roles() {
      const roles = JSON.parse(GetCookie("roles"));
      return roles || [];
    },
    Permissions() {
      const permissions = JSON.parse(GetCookie("permissions"));
      return permissions;
    },
    HasRoles(arrRoles) {
      const roles = this.Roles();
      for (let i = 0; i < arrRoles.length; i++) {
        if (roles.find(r => r.name == arrRoles[i])) {
          return true;
        }
      }
      return false;
    },
    HasPermission(permission) {
      return permission;
    },
    SaveSession(data) {
      SetCookie("token", data.token);
      SetCookie("tokenExpiration", data.expiration);
      const user = JSON.stringify(data.user);
      SetCookie("user", user);
      const roles = JSON.stringify(data.roles);
      SetCookie("roles", roles);
      const permissions = JSON.stringify(data.permissions);
      SetCookie("permissions", permissions);
    },
    RemoveSession() {
      SetCookie("token", null);
      SetCookie("tokenExpiration", null);
      SetCookie("user", null);
      SetCookie("roles", null);
      SetCookie("permissions", null);
    },
  }
};
</script>