import { createRouter, createWebHistory } from 'vue-router';
import UserAuth from '@/Constants/UserAuth.vue';
import LoginPage from '@/Pages/LoginPage.vue'
// import Account from '@/Pages/SU/Account.vue'
const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: () => import('@/Pages/NotFoundPage.vue')
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('@/Pages/UnauthorizedPage.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/privacy-policies',
    name: 'privacy-policies',
    component: () => import('@/Pages/PrivacyPoliciesPage.vue')
  },
  //#region Test
  {
    path: '/test/create-locations',
    name: 'test.create-locations',
    component: () => import('@/Pages/Test/CreateLocations.vue')
  },
  {
    path: '/test/history-locations',
    name: 'test.history-locations',
    component: () => import('@/Pages/Test/HistoryLocations.vue')
  },
  //#endregion
  //#region Passenger
  {
    path: '/',
    name: 'passenger.index',
    component: () => import('@/Pages/Passenger/CalculatePage.vue')
  },
  {
    path: '/micros',
    name: 'passenger.micros',
    component: () => import('@/Pages/Passenger/MicrosPage.vue')
  },
  {
    path: '/minibuses',
    name: 'passenger.minibuses',
    component: () => import('@/Pages/Passenger/MinibusesPage.vue')
  },
  {
    path: '/trufis',
    name: 'passenger.trufis',
    component: () => import('@/Pages/Passenger/TrufisPage.vue')
  },
  {
    path: '/setting',
    name: 'passenger.setting',
    component: () => import('@/Pages/Passenger/SettingPage.vue')
  },
  //#endregion
  //#region SU
  {
    path: '/su',
    name: 'su.index',
    component: () => import('@/Pages/SU/GremialList.vue'),
    meta: { requiresAuth: true, roles: ['su'] }
  },
  {
    path: '/su/lines',
    name: 'su.lines',
    component: () => import('@/Pages/SU/LineList.vue')
  },
  {
    path: '/su/brands',
    name: 'su.brands',
    component: () => import('@/Pages/SU/BrandListPage.vue')
  },
  {
    path: '/su/models',
    name: 'su.models',
    component: () => import('@/Pages/SU/ModelListPage.vue')
  },
  {
    path: '/su/vehicles',
    name: 'su.vehicles',
    component: () => import('@/Pages/SU/VehicleListPage.vue')
  },
  {
    path: '/su/owners',
    name: 'su.owners',
    component: () => import('@/Pages/SU/OwnerListPage.vue')
  },
  {
    path: '/su/account',
    name: 'su.account',
    component: LoginPage
  },
  //#endregion 
  //#region Admin Association
  {
    path: '/admin/:gremial_name',
    component: () => import('@/Pages/AdminGremial/GremialPage.vue'),
    name: 'admin.gremial.data'
  },
  {
    path: '/admin/:gremial_name/lines',
    component: () => import('@/Pages/AdminGremial/GremialLines.vue'),
    name: 'admin.gremial.lines'
  },
  {
    path: '/admin/:gremial_name/internals',
    component: () => import('@/Pages/AdminGremial/InternalsPage.vue'),
    name: 'admin.gremial.internals'
  },
  {
    path: '/admin/:gremial_name/groups',
    component: () => import('@/Pages/AdminGremial/GroupsPage.vue'),
    name: 'admin.gremial.groups'
  },
  {
    path: '/admin/:gremial_name/planning',
    component: () => import('@/Pages/AdminGremial/PlanningPage.vue'),
    name: 'admin.gremial.planning'
  },
  {
    path: '/admin/:gremial_name/reports',
    component: () => import('@/Pages/AdminGremial/ReportsPage.vue'),
    name: 'admin.gremial.reports'
  },
  {
    path: '/admin/:gremial_name/account',
    component: () => import('@/Pages/AdminGremial/AccountPage.vue'),
    name: 'admin.account'
  },
  //#endregion
  //#region Admin Line
  {
    path: '/admin/:gremial_name/:line_name',
    component: () => import('@/Pages/AdminLine/IndexPage.vue'),
    name: 'admin.line.index'
  },
  {
    path: '/admin/:gremial_name/:line_name/data',
    component: () => import('@/Pages/AdminLine/DataPage.vue'),
    name: 'admin.line.data'
  },
  {
    path: '/admin/:gremial_name/:line_name/route',
    component: () => import('@/Pages/AdminLine/RoutePage.vue'),
    name: 'admin.line.route'
  },
  {
    path: '/admin/:gremial_name/:line_name/points',
    component: () => import('@/Pages/AdminLine/PointsPage.vue'),
    name: 'admin.line.points'
  },
  {
    path: '/admin/:gremial_name/:line_name/switches',
    component: () => import('@/Pages/AdminLine/SwitchPage.vue'),
    name: 'admin.line.switches'
  },
  {
    path: '/admin/:gremial_name/:line_name/roles',
    component: () => import('@/Pages/AdminLine/RolesPage.vue'),
    name: 'admin.line.roles'
  },
  {
    path: '/admin/:gremial_name/:line_name/drivers',
    component: () => import('@/Pages/AdminLine/DriversPage.vue'),
    name: 'admin.line.drivers'
  },
  {
    path: '/admin/:gremial_name/:line_name/account',
    component: () => import('@/Pages/AdminLine/AccountPage.vue'),
    name: 'admin.line.account'
  },
  {
    path: '/admin/:gremial_name/:line_name/setting',
    component: () => import('@/Pages/AdminLine/SettingPage.vue'),
    name: 'admin.line.setting'
  },
  //#endregion
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!UserAuth.methods.IsLogin() || UserAuth.methods.IsTokenExpired()) {
      localStorage.setItem('redirectPath', to.fullPath);
      next('/login');
    } else if (!UserAuth.methods.HasRoles(to.meta.roles)) {
      next('/unauthorized');
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;