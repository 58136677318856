<template>
  <div class="mx-auto  flex w-full max-w-sm flex-col">
    <div id="logo" class="mx-auto flex">
      <LogoMango />
    </div>
    <h1 class="mt-8 text-2xl font-semibold text-gray-700 lg:mt-0">
      Bienvenido de Nuevo
    </h1>
    <p class="mt-2 text-sm text-gray-400">Porfavor ingresa tus datos</p>
    <p class="mt-5 text-sm font-semibold text-gray-500">Correo</p>
    <input v-model="Email" class="mt-1 rounded border py-1 px-3 text-sm shadow" />
    <p class="mt-5 text-sm font-semibold text-gray-500">Contraseña</p>
    <input v-model="Password" class="mt-1 rounded border py-1 px-3 text-sm text-sm shadow" type="password" />
    <div class="mt-5 flex items-center">
      <input class="mr-2 align-middle text-sm text-sm" type="checkbox" />
      <p class="text-sm font-semibold text-gray-500">Recordarme</p>
      <span class="flex-1" />
      <a href="#" class="text-sm font-semibold text-indigo-400 hover:text-indigo-500">Olvidaste tu Contraseña?</a>
    </div>
    <button @click="Login()"
      class="mt-5 rounded border bg-black py-2 px-5 text-sm text-sm font-semibold text-gray-50 shadow hover:bg-indigo-500">
      Iniciar Sesion
    </button>
    <div class="mt-8 flex items-center space-x-1">
      <p class="text-sm font-semibold text-gray-500">No tienes Cuenta?</p>
      <a href="#" class="text-sm font-semibold text-indigo-400 hover:text-indigo-500">Registrate</a>
    </div>
  </div>
</template>
<script>
import { urls } from '@/Constants/Apis.js';
import { Post } from '@/Constants/Fetch.js';
import LogoMango from "@/Componentes/LogoMango.vue";
import UserAuth from '@/Constants/UserAuth.vue';
export default {
  components: { LogoMango },
  data() {
    return {
      Email: null,
      Password: null,
    }
  },
  mixins: [UserAuth],
  methods: {
    async Login() {
      const datos = {
        "email": this.Email,
        "password": this.Password,
      };
      await Post(
        urls.Login,
        datos,
        (data) => {
          this.SaveSession(data);
          const redirectPath = localStorage.getItem('redirectPath');
          console.log("+++", redirectPath);
          if (redirectPath) {
            localStorage.removeItem('redirectPath');
            return this.$router.replace(redirectPath);
          }
          if (data.roles) {
            const role = data.roles[0];
            if (role.name == 'su') {
              return this.$router.replace({ name: 'su.index' });
            }
            else if (role.name == 'admin') {
              return this.$router.replace({ name: 'passenger.setting' });
            }
          }
          return this.$router.replace({ name: 'passenger.index' });
        },
        () => {
          alert("Datos Incorrectos");
        }
      );
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 2500px) {
  div#logo {
    display: none;
  }
}

@media (max-width: 800px) {
  div#logo {
    display: flex;
  }
}

@media (max-width: 500px) {
  div#logo {
    display: flex;
  }
}
</style>