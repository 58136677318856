import UserAuth from '@/Constants/UserAuth.vue';
import router from '@/router';
export async function Get(url, functionSuccess, functionError) {
    await fetch(
        url
        , {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${UserAuth.methods.GetToken()}`,
                'Content-type': 'application/json; charset=UTF-8'
            }
        }
    ).then(async response => {
        if (response.ok) {
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                await response.json().then(async (data) => {
                    console.log("data", data);
                    await functionSuccess(data);
                })
            }
            else if (contentType && contentType.includes('text')) {
                await response.text().then(async (data) => {
                    console.log("data", data);
                    await functionSuccess(data);
                })
            }
            else {
                throw new Error('Tipo de respuesta no soportado');
            }
        }
        else {
            await response.text().then(async (err) => {
                if(response.status==401){
                    if(UserAuth.methods.IsTokenExpired()){
                        localStorage.setItem('redirectPath', location.pathname);
                        return router.push({name: 'login'});
                    }
                    else
                        return alert("Inautorizado")
                }
                console.error("error1: ", err);
                await functionError(err);
            })
        }
    }).catch(async err => {
        console.error("error2: ", err);
        await functionError(err);
    })
}

export async function Post(url, data, functionSuccess, functionError) {
    console.log("Datos a Agregar:", data);
    await fetch(
        url
        , {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Authorization': `Bearer ${UserAuth.methods.GetToken()}`,
                'Content-type': 'application/json; charset=UTF-8'
            }
        }
    ).then(async (response) => {
        console.log("response: ", response);
        if (response.ok) {
            await response.json().then((data) => {
                console.log("data", data);
                functionSuccess(data);
            })
        }
        else {
            await response.text().then(err => {
                console.error("error: ", err);
                functionError(err);
            })
        }
    }).catch((err) => {
        console.error("error: ", err);
        functionError(err);
    })
}

export async function Put(url, id, data, functionSuccess, functionError) {
    console.log("Datos a Actualizar:", data);
    await fetch(
        url + '/' + id
        , {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Authorization': `Bearer ${UserAuth.methods.GetToken()}`,
                'Content-type': 'application/json; charset=UTF-8'
            }
        }
    ).then(response => {
        console.log("response: ", response);
        if (response.ok) {
            functionSuccess();
        }
        else {
            response.text().then(err => {
                console.error("error: ", err);
                functionError(err);
            })
        }
    }).catch(err => {
        console.error("error: ", err);
        functionError(err);
    })
}

export async function Delete(url, id, functionSuccess, functionError) {
    console.log("ID del Registro a Eliminar:", id);
    await fetch(
        url + '/' + id
        , {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${UserAuth.methods.GetToken()}`,
                'Content-type': 'application/json; charset=UTF-8'
            }
        }
    ).then(response => {
        console.log("response: ", response);
        if (response.ok) {
            functionSuccess();
        }
        else {
            response.text().then(err => {
                console.error("error: ", err);
                functionError(err);
            })
        }
    }).catch(err => {
        console.error("error: ", err);
        functionError(err);
    })
}