function CurrentThemeIsDark(){
    const theme = window.localStorage.getItem('theme');
    return theme == 'dark';
}

function ChangeTheme(theme){
    const root = document.querySelector(':root');
    // var storedTheme = window.localStorage.getItem('theme') || (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
    if(theme=='dark'){
        // const styles = getComputedStyle(root);
        // styles.getPropertyValue('--blue')
        root.style.setProperty('--color-0','#DDDDDD');
        root.style.setProperty('--color-1','#898989');
        root.style.setProperty('--color-2','#757575');
        root.style.setProperty('--color-3','#606060');
        root.style.setProperty('--color-4','#404040');
        root.style.setProperty('--color-5','#202020');
        root.style.setProperty('--color-6','#000000');
        root.style.setProperty('--color-text-0','#FFFFFF');
        root.style.setProperty('--color-text-1','#999999');
        root.style.setProperty('--color-text-2','#727272');
        // document.documentElement.setAttribute('data-theme', theme)
        window.localStorage.setItem('theme', 'dark');
    }
    else if(theme=='red'){
        root.style.setProperty('--color-0','#d5294d');
        root.style.setProperty('--color-1','#ea546c');
        root.style.setProperty('--color-2','#f27a8a');
        root.style.setProperty('--color-3','#f7aab2');
        root.style.setProperty('--color-4','#fbd0d5');
        root.style.setProperty('--color-5','#fde6e7');
        root.style.setProperty('--color-6','#fef2f3');
        root.style.setProperty('--color-text-0','#000000');
        root.style.setProperty('--color-text-1','#757575');
        root.style.setProperty('--color-text-2','#A0A0A0');
        // document.documentElement.setAttribute('data-theme', 'light')
        window.localStorage.setItem('theme', 'light');
    }
    else{//POR DEFECTO LIGHT
        root.style.setProperty('--color-0','#2980F2');
        root.style.setProperty('--color-1','#3E8DF4');
        root.style.setProperty('--color-2','#6EA9F7');
        root.style.setProperty('--color-3','#9FC6F9');
        root.style.setProperty('--color-4','#CFE2FC');
        root.style.setProperty('--color-5','#E8F7FD');
        root.style.setProperty('--color-6','#FFFFFF');
        root.style.setProperty('--color-text-0','#000000');
        root.style.setProperty('--color-text-1','#757575');
        root.style.setProperty('--color-text-2','#A0A0A0');
        // document.documentElement.setAttribute('data-theme', 'light')
        window.localStorage.setItem('theme', 'light');
    }
}
module.exports = {
    "ChangeTheme": ChangeTheme,
    "CurrentThemeIsDark": CurrentThemeIsDark
}